<template>
  <base-section id="Agenda-feature">
    <div class="text-center text-h4 my-10">
      {{ $t("faqFeature.line1") }}
    </div>
    <v-card flat class="ma-10">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            {{ $t("faqFeature.q1") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t("faqFeature.a1") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            {{ $t("faqFeature.q2") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t("faqFeature.a2") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            {{ $t("faqFeature.q3") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t("faqFeature.a3") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            {{ $t("faqFeature.q4") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t("faqFeature.a4") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            {{ $t("faqFeature.q5") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t("faqFeature.a5") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6">
            {{ $t("faqFeature.q6") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t("faqFeature.a6") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatureProFAQ",

  data: () => ({}),
  methods: {},
};
</script>
